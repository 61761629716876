import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useRef, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  ButtonGroup,
  Input,
  Select,
  Text,
  useToast,
  Icon,
  Spacer,
  Flex,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Link,
  Textarea,
  Image,
  CardBody,
  Card as ChakraCard,
  Grid,
  GridItem,
  Heading,
  CardFooter,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  Divider,
  AbsoluteCenter,
  Center,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  InputLeftElement,
  FormErrorMessage,
  InputGroup,
  Switch,
} from "@chakra-ui/react";

import { FiFile } from "react-icons/fi";

import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";

import { getActionsPermission } from "../../../helpers/api/auth";
import LoaderSimple from "../../../components/LoaderSimple";
import { css } from "@emotion/react";
import FileUploader from "../../../components/FileUploader";

/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Configurações",
  path: "/base/configs/",
  apiBase: "/bancas/configs/",
};

/**
 * COMPONENT
 */
const Configs = () => {
  /**
   * HOOKS
   */
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();

  const [banner, setBanner] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [enable_cashout_tax, setEnableCashoutTax] = useState(false);
  const [enable_double_gift, setEnableDoubleGift] = useState(false);
  const [network_friends, setNetwork_friends] = useState(0);
  const [comission_raffle, setComission_raffle] = useState(0);
  const [comission_farms, setComission_farms] = useState(0);
  const [comission_roulette, setComission_roulette] = useState(0);
  const [disabledLotteries, setDisabledLotteries] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [lotteries, setLotteries] = useState<any[]>([]);
  const [title, setTitle] = useState("");
  const [action, setAction] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();
  const { publish } = useEvent();
  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async () => {
    setIsLoading(true);
    try {
      await api.get(configs.apiBase, null).then((response) => {
        setBanner(response.data.banner ?? null);
        setComission_farms(response.data.comission_farms ?? 0);
        setComission_raffle(response.data.comission_raffle ?? 0);
        setNetwork_friends(response.data.network_friends ?? 0);
        setComission_roulette(response.data.comission_roulette ?? 0);
        setEnableCashoutTax(response.data.enable_cashout_tax ?? 0);
        setEnableDoubleGift(response.data.enable_double_gift ?? 0);
      });

      await api.get("/bancas/lotteries", null)?.then((response) => {
        setCategories(response?.data?.categories ?? []);
        setLotteries(response?.data?.lotteries ?? []);
      });

      await api.get("/bancas/disabled-lotteries", null)?.then((response) => {
        setDisabledLotteries(response?.data ?? []);
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar configurações.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  const handlePageLoading = useCallback(async () => {
    setTitle("");
    setAction("");
    setSelectedFile(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  const handleSaveFarm = async () => {
    const payload = {
      comission_raffle,
      comission_farms,
      comission_roulette,
      network_friends,
      enable_cashout_tax: enable_cashout_tax ? 1 : 0,
      enable_double_gift: enable_double_gift ? 1 : 0,
      disabledLotteries: JSON.stringify(disabledLotteries),
    };

    if (
      window.confirm(
        "Você tem certeza de que todos os dados estão corretos? Se tens dúvida, clique em cancelar e verifique novamente."
      ) === false
    )
      return;

    dispatch(toggleLoader(true));

    try {
      await api.create("/bancas/configs", payload).then((response) => {
        toast({
          title: "Configuração salva com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      });
    } catch (e) {
      toast({
        title: "Erro ao salvar configuração.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    publish("deleteItem", null);
    dispatch(toggleLoader(false));
  };

  const handleRemoveBanner = async () => {
    dispatch(toggleLoader(true));

    try {
      await api.create("/bancas/configs/remove-banner", {}).then((response) => {
        toast({
          title: "Banner removido com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      });
    } catch (e) {
      toast({
        title: "Erro ao remover banner.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    publish("deleteItem", null);
    dispatch(toggleLoader(false));
  };

  const handleSaveConfigsBanner = async () => {
    dispatch(toggleLoader(true));
    if (selectedFile) {
      const payload = {
        title,
        banner: selectedFile,
        link: action,
      };

      try {
        dispatch(toggleLoader(true));
        await api
          .createWithFile("/bancas/configs/banner", payload)
          .then((response) => {
            toast({
              title: "Banner salvo com sucesso.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          });
      } catch (e) {
        toast({
          title: "Erro ao salvar banner.",
          description: e?.message || "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      //toast
      toast({
        title: "Selecione uma imagem.",
        description: "",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
    publish("deleteItem", null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSaveConfigs = async () => {
    await handleSaveFarm();
  };

  const handleToggleLottery = (id: number) => {
    let oldStatus;
    const find = disabledLotteries.find((lot) => lot.idLottery === id);

    if (find) {
      oldStatus = find.value;
    } else {
      oldStatus = true;
    }

    const newStatus = !oldStatus;

    const payload = {
      idLottery: id,
      value: newStatus,
    };

    const removed = disabledLotteries.filter((lot) => lot.idLottery !== id);

    setDisabledLotteries([...removed, payload]);
  };

  const findIfIsChecked = (id: number) => {
    const find = disabledLotteries.find((lot) => lot.idLottery === id);

    if (find) {
      return find.value;
    }

    return true;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type;
      const validTypes = ["image/jpeg", "image/png", "image/bmp", "image/webp"];
      if (validTypes.includes(fileType)) {
        setSelectedFile(file);
      } else {
        alert(
          "Por favor, selecione um arquivo de imagem válido (jpg, jpeg, png, bmp, webp)."
        );
      }
    }
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              {isLoading ? <LoaderSimple /> : null}

              <Row>
                <Tabs variant="enclosed">
                  <TabList
                    overflowX="auto"
                    css={css({
                      scrollbarWidth: "none",
                      "::-webkit-scrollbar": { display: "none" },
                      "-webkit-overflow-scrolling": "touch",
                    })}
                  >
                    <Tab>Configurações</Tab>
                    <Tab>Loterias</Tab>
                    <Tab>Banners</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {/* Configurações */}
                      <Row>
                        <Col md={6}>
                          <Text fontWeight={"bold"} fontSize={"lg"}>
                            Comissões dos promotores:
                          </Text>

                          <FormControl mb={2}>
                            <FormLabel>Fazendinha: (%)</FormLabel>
                            <NumberInput
                              max={7}
                              min={0}
                              value={comission_farms}
                              onChange={(value) =>
                                setComission_farms(Number(value))
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>Roletinha/Raspadinha: (%)</FormLabel>
                            <NumberInput
                              max={7}
                              min={0}
                              value={comission_roulette}
                              onChange={(value) =>
                                setComission_roulette(Number(value))
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>Rede de Amigos: (%)</FormLabel>
                            <NumberInput
                              max={5}
                              min={0}
                              value={network_friends}
                              onChange={(value) =>
                                setNetwork_friends(Number(value))
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>

                          <Button
                            mt={4}
                            colorScheme="teal"
                            type="submit"
                            onClick={handleSaveConfigs}
                          >
                            Salvar configurações
                          </Button>
                        </Col>

                        <Col md={6}>
                          <Text fontWeight={"bold"} fontSize={"lg"}>
                            Configurações opcionais:
                          </Text>

                          <FormControl mb={2}>
                            <FormLabel>Taxa de saque (1%):</FormLabel>
                            <Switch
                              id="enable_cashout_tax"
                              size="lg"
                              isChecked={enable_cashout_tax}
                              onChange={() =>
                                setEnableCashoutTax(!enable_cashout_tax)
                              }
                            />
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>Milhar brinde dobrada:</FormLabel>
                            <Switch
                              id="enable_double_gift"
                              size="lg"
                              isChecked={enable_double_gift}
                              onChange={() =>
                                setEnableDoubleGift(!enable_double_gift)
                              }
                            />
                          </FormControl>
                        </Col>
                      </Row>
                      {/* Configurações */}
                    </TabPanel>
                    <TabPanel>
                      {/* Loterias */}
                      <Col md={12}>
                        <Box width={"100%"}>
                          {categories.map((category: any) => {
                            return (
                              <div key={category.idCategory}>
                                <Box position="relative" padding="1">
                                  <Divider />
                                  <AbsoluteCenter
                                    bg="white"
                                    px="4"
                                    fontWeight={"bold"}
                                  >
                                    {category.title}
                                  </AbsoluteCenter>
                                </Box>
                                <Center>
                                  <TableContainer width={"100%"}>
                                    <Table size="sm">
                                      <Thead>
                                        <Tr>
                                          <Th>Loteria</Th>
                                          <Th w={50}>Código</Th>
                                        </Tr>
                                      </Thead>
                                      <Tbody>
                                        {lotteries
                                          .filter(
                                            (lot: any) =>
                                              lot.idCategory ===
                                              category.idCategory
                                          )
                                          .map((lottery: any, i: number) => {
                                            return (
                                              <Tr>
                                                <Td py={0}>
                                                  <Checkbox
                                                    p={2}
                                                    width="100%"
                                                    _hover={{
                                                      backgroundColor:
                                                        "gray.100",
                                                    }}
                                                    size="md"
                                                    colorScheme="orange"
                                                    isChecked={findIfIsChecked(
                                                      lottery.idLottery
                                                    )}
                                                    onChange={() =>
                                                      handleToggleLottery(
                                                        lottery.idLottery
                                                      )
                                                    }
                                                  >
                                                    {lottery.title}
                                                  </Checkbox>
                                                </Td>
                                                <Td isNumeric>
                                                  {lottery.code}
                                                </Td>
                                              </Tr>
                                            );
                                          })}
                                      </Tbody>
                                    </Table>
                                  </TableContainer>
                                </Center>
                              </div>
                            );
                          })}
                          <Center>
                            <Button
                              mt={4}
                              colorScheme="teal"
                              type="submit"
                              onClick={handleSaveConfigs}
                            >
                              Salvar configurações
                            </Button>
                          </Center>
                        </Box>
                      </Col>
                      {/* Loterias */}
                    </TabPanel>
                    <TabPanel>
                      {/* Banners */}
                      <Row>
                        <Col md="6">
                          <Text fontWeight={"bold"} fontSize={"lg"}>
                            Banner de entrada:
                          </Text>

                          <FormControl mb={2}>
                            <FormLabel>Titulo do banner:</FormLabel>
                            <Input
                              maxLength={30}
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>Banner:</FormLabel>

                            <Input
                              type="file"
                              accept=".jpg,.jpeg,.png,.bmp,.webp"
                              onChange={handleFileChange}
                              ref={fileInputRef}
                            />
                          </FormControl>

                          <FormControl mb={2}>
                            <FormLabel>Ação:</FormLabel>
                            <Select
                              value={action}
                              onChange={(e) => setAction(e.target.value)}
                            >
                              <option value="">SEM AÇÃO</option>
                              <option value="promotor">
                                Falar com Promotor
                              </option>
                              <option value="amigos">Rede de Amigos</option>
                              <option value="fazendinha">Fazendinha</option>
                              <option value="roletinha">Roletinha</option>
                              <option value="jogos">Jogo do bicho</option>
                              <option value="raspadinha">Raspadinha</option>
                              <option value="recarga-pix">Recargas</option>
                              <option value="saque-listar">Saques</option>
                              <option value="relatorios">Relatórios</option>
                            </Select>
                          </FormControl>

                          <Button
                            mt={4}
                            colorScheme="teal"
                            type="submit"
                            onClick={handleSaveConfigsBanner}
                          >
                            Salvar configurações
                          </Button>
                          {/* Banners */}
                        </Col>
                        <Col md="6">
                          {banner && banner?.title && (
                            <>
                              <hr />
                              <Text fontWeight={"bold"} fontSize={"lg"}>
                                Banner atual:
                              </Text>
                              <Box mt={2}>
                                <Text fontWeight={"bold"} fontSize={"md"}>
                                  TÍTULO: {banner?.title}
                                </Text>
                                <Text
                                  fontWeight={"bold"}
                                  fontSize={"md"}
                                  textTransform={"uppercase"}
                                >
                                  AÇÃO: {banner?.link}
                                </Text>
                                <Image
                                  src={banner?.banner}
                                  alt="Preview"
                                  w={"100%"}
                                />
                              </Box>

                              <Button
                                mt={4}
                                colorScheme="red"
                                type="submit"
                                onClick={handleRemoveBanner}
                              >
                                Remover banner atual
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Configs;
